.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cookie-consent-banner p {
    margin: 0;
    font-size: 14px;
  }
  
  .cookie-consent-banner a {
    color: #f1c40f;
    text-decoration: underline;
  }
  
  .cookie-consent-buttons {
    display: flex;
    gap: 10px;
  }
  
  .cookie-consent-buttons button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .accept-btn {
    background-color: #27ae60;
    color: white;
  }
  
  .decline-btn {
    background-color: #c0392b;
    color: white;
  }
  