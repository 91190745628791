.notification-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #F44336;
    color: white;
    padding: 15px 20px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.3s ease-in-out;
  }
  