.welcome-page {
    font-family: Arial, Helvetica, sans-serif; /* Use standard system fonts */
    background-color: #f4f7fc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    color: #333;
  }
  
  .welcome-page h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .welcome-page p {
    font-size: 1.2rem;
    font-weight: normal;
    color: #34495e;
    margin-bottom: 30px;
  }
  
  .welcome-page ul {
    list-style-type: none;
    padding: 0;
  }
  
  .welcome-page li {
    margin: 15px 0;
  }
  
  .welcome-page li:nth-child(odd) a {
    background-color: #3498db; /* Default blue for odd items */
  }
  
  .welcome-page li:nth-child(even) a {
    background-color: #e67e22; /* Orange for even items */
  }
  
  /* You can define additional colors for more items if needed */
  .welcome-page li:nth-child(3) a {
    background-color: #9b59b6; /* Purple */
  }
  
  .welcome-page li:nth-child(4) a {
    background-color: #2ecc71; /* Green */
  }
  
  .welcome-page a {
    font-size: 1.2rem;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .welcome-page a:hover {
    background-color: #2980b9; /* Hover effect - override color */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .welcome-page a:active {
    transform: translateY(2px);
  }
  